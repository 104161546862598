<template>
    <modal ref="modalDetallePago" titulo="Comisión Pagada" tamano="modal-lg" cerrar no-cancelar no-aceptar>
        <div class="row mx-0 align-items-center">
            <img :src="pago.logo_lechero" width="55" height="55" class="rounded-circle border" />
            <div class="col-3">
                <p class="text-general f-17 f-500">
                    {{ pago.lechero }}
                </p>
                <p class="text-general f-13">
                    {{ pago.cedis_nombre }}
                </p>
            </div>
            <div class="col-3 text-center text-general">
                <p class="f-20"> <b>{{ separadorNumero(pago.valor) }}</b> {{ sigla }}</p>
                <p>
                    <i class="icon-comision f-14 text-general" />
                    Valor Pagado
                </p>
            </div>
            <img :src="pago.foto_encargado" width="55" height="55" class="rounded-circle border" />
            <div class="col">
                <p class="text-general f-17 f-500">
                    {{ pago.encargado }}
                </p>
                <p class="text-general f-13">
                    Encargado del pago - {{pago.created_at | helper-fecha('DD-MMM-Y')}}
                </p>
            </div>
        </div>
        <div class="row text-general my-3 mt-4 ml-5">
            <div class="col-12 px-0">
                <p class="f-16 f-500">Observacion del pago</p>
            </div>
            <p class="f-14">{{pago.comentario ? pago.comentario : 'No registra comentario'}}</p>
        </div>
        <div class="row mx-0 pt-4 ">
            <div class="col-8 text-general f-16 f-500">
                {{ pedidos.length }} Pedidos comisionados
            </div>
            <div class="col-3">
                <el-input v-model="buscar" size="small" class="w-100 br-20" placeholder="Buscar por ID" />
            </div>
            <div class="col-12">
                <div class="custom-scroll overflow-auto" :style="`height:${alto_tabla}`">
                    <el-table :data="pedidos.filter(data => !buscar || data.id == buscar)" header-row-class-name="text-general" style="width: 100%">
                        <el-table-column prop="id" label="Pedido" class-name="text-center text-general" />
                        <el-table-column label="Fecha Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ row.entrega_fecha | helper-fecha('DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.total,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Comisión" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.valor_condicion,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    data(){
        return {

            buscar: '',
            alto_tabla: 'calc(100vh - 592px)',
            pedidos:[],
            pago:{}
        }
    },
    computed:{
        sigla(){
            let m = this.monedas.find(o=>o.id === this.pago.idm_moneda)
            if(m){ return m.sigla }
            return ''
        }
    },
    methods: {
        toggle(id){
            this.detallePago(id)
            this.$refs.modalDetallePago.toggle();
        },
        async detallePago(id){
            try {

                const {data} = await Liquidacion.detalleComisionPagada(id)
                this.pedidos = data.pedidos
                this.pago = data.pago

            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>
